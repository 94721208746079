const now = new Date();
export const lessonList = [
    {
        earliest_potential_start: "2021-09-09T13:01:13.187Z",
        applications: [
            {
                arrival: now.toISOString(),
                teacher: {
                    name: "Knut",
                    rating: 1.0
                }
            },
            {
                arrival: now.toISOString(),
                teacher: {
                    name: "Berto",
                    rating: 1.0
                }
            },
        ]
    },
    {
        earliest_potential_start: "2021-09-012T13:01:13.187Z",
        applications: [
            {
                arrival: now.toISOString(),
                teacher: {
                    name: "Knut2",
                    rating: 1.0
                }
            },
            {
                arrival: now.toISOString(),
                teacher: {
                    name: "Berto2",
                    rating: 1.0
                }
            },
        ]
    },
    {
        earliest_potential_start: "2021-09-20T13:01:13.187Z",
        applications: [
            {
                arrival: now.toISOString(),
                teacher: {
                    name: "Knut3",
                    rating: 1.0
                }
            }
        ]
    },
]