import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import styles from './Login.module.css';
//mport 'react-notifications-component/dist/theme.css'
//import { store } from 'react-notifications-component';

//import ReactNotification from 'react-notifications-component'

import { requestPerm } from 'services/notifications'

const initialFormData = Object.freeze({
    email: "",
    password: "",
});

function update_token(res) {
    res.then(r => localStorage.setItem('access_token', r['access_token']), err => alert(err));
}

function Login({ refreshData, setSigned_in, setPage }) {
    const [formData, setFormData] = React.useState(initialFormData);
    const [errMsg, setErrMsg] = React.useState("")
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        requestPerm();
        fetch('/api/login', { method: 'POST', headers: { "content-type": "application/json" }, body: JSON.stringify(formData) })
            .then(res => {
                if (!res.ok) {
                    if (res.status === 401) { //Tell user about invalid password or email etc..
                        res.json().then(r => {
                            setErrMsg(r.detail.msg)
                            setTimeout(() => setErrMsg(""), 3000);
                        })
                    }
                }
                else {
                    let counter = 0;
                    const refresh = () => {
                        try {
                            refreshData();
                        } catch (err) {
                            console.log(err);
                            if (counter < 10) {
                                counter += 1;
                                setTimeout(refresh, 100);
                            }
                        }
                    }
                    res.json().then(r => {
                        localStorage.setItem('access_token', r['access_token']);
                        setSigned_in(true);
                        refresh();
                    }, err => alert(err));
                }
            }).catch(error => alert(error.message));

    }
    React.useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleSubmit(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [formData]);

    return (
        <div className={styles.Login}>
            <header className={styles.Login_header}>
                <h1>GradeLift</h1>
                {errMsg && <span className={`f3 reject-theme ${styles.errMsg}`}>
                    {errMsg}
                </span>}
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="6 col-centered">
                            <form>
                                <div>
                                    <MDBInput label="Email" icon="envelope" group type="email" validate error="wrong"
                                        success="right" name="email" onChange={handleChange} />
                                    <MDBInput label="Password" icon="lock" group type="password" validate name="password" onChange={handleChange} />
                                </div>
                                <div className="text-center">
                                    <MDBBtn className={styles.login_btn} onClick={handleSubmit}>Logga in</MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <p>Don't have an account? <a className={styles.sign_up_link} onClick={_ => setPage(1)}>Sign up now</a></p>
            </header>
        </div >
    );
}

export default Login;