import React, {useState, useEffect} from 'react';

import {dateStrToSwe} from 'services/formats/dates';
import {customPost} from 'services/api/promises';
import SwipeCard from './SwipeCard';
import globals from 'globalData';

const respondLess = async applicationID=>{
    const res = await customPost(`/api/lesson/student/respond/${applicationID}`, {"accept": true}, {});
    const parsed = await res.json();
    const {PaymentRequestToken: token} = parsed.payment_data;
    window.location = `swish://paymentrequest?token=${token}&callbackurl=${globals.appUrl}/home`;
};

function LessonHandler({lesson, match=()=>{}, remove=()=>{}}) {
    const [index, setIndex] = useState(0);
    useEffect(()=>setIndex(0), [lesson]);
    useEffect(()=>{
        if(index >= lesson.applications.length)
            remove();
    }, [index, remove]);

    return index < lesson.applications.length && <>
        <h2 className="f1 text-center mt-5">Lektion {dateStrToSwe(lesson.earliest_potential_start)}</h2>
        <SwipeCard
            application={lesson.applications[index]}
            accept={()=>{
                (async ()=>{
                    await respondLess(lesson.applications[index].id)
                    match();
                })();
            }}
            reject={()=>setIndex(index+1)}>
        </SwipeCard>
    </>
};

export default LessonHandler;
