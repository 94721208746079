import React, {useState, useEffect} from 'react'

import Loading from 'components/Loading'
import { useFetch} from 'hooks/fetch-hooks'
import {MDBRow, MDBCol, MDBContainer} from "mdbreact";
import { CustomRating } from 'components/RateLesson'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FinishedLesson from 'components/LessonDisplay/FinishedLesson'

const History = ()=>{
    const {data: finishedLessons, isLoading} = useFetch('/api/lesson/student/history', {authorize:true});
    const [selectedLesson, setSelectedLesson] = useState(-1);
    
    if(isLoading) return <Loading></Loading>
    return (
        <section className="history">
            <h1 className="f2 text-center cap">Mina Tidigare Lektioner</h1>
            {
                finishedLessons?.map(lesson=><FinishedLesson key={lesson.id} lesson={lesson} onClick={()=>{}}></FinishedLesson>)
            }
        </section>
    )
}
export default History
