import React from 'react';

import Login from './Login';
import Registration from './Registration';

export function Auth({setSigned_in, refreshData}) {
    const [page, setPage] = React.useState(0);
    switch (page) {
        case 1:
            return <Registration setPage={setPage} setSigned_in={setSigned_in} />
        default:
            return <Login setPage={setPage} setSigned_in={setSigned_in} refreshData={refreshData} />
    }
}

export default Auth;