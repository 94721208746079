import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import React from 'react';
import validate from 'formValidation.js';

import './Registration.css';

// Initial form data
const initialFormData = Object.freeze({
    name: "",
    birthyear: "",
    phonenumber: "",
    default_address: "",
    email: "",
    password: "",
});


// Registration component for user signup
function Registration({ setPage }) {
    // State to store form data
    const [formData, setFormData] = React.useState(initialFormData);
    // Ref for form element for validation
    const formRef = React.useRef(null);
    // State to store validation status
    const [isValidated, setIsValidated] = React.useState(false);


    // Update form data on change
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    }

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Check validity of form fields
        if (formRef.current.checkValidity()) {
            // Send form data to server
            fetch('/api/signup', { method: 'POST', headers: { "content-type": "application/json" }, body: JSON.stringify(formData) })
                .then(res => {
                    if (!res.ok) {
                        // Display error message
                        res.json().then(json => { alert(json.detail[0].msg) });
                    } else {
                        setPage(0);
                    }
                }).catch(error => alert(error.message));
        };
    };

    return (
        <div className="Registration">
            <header className="Registration-header">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="6 col-centered">
                            <form ref={formRef} className={`${isValidated ? 'was-validated' : 'needs-validation'}`} onSubmit={handleSubmit}>
                                <h1 className="f2 m-4">Skapa konto</h1>
                                <div className="grey-text">
                                    <MDBInput
                                        label="För- och efternamn"
                                        icon="user"
                                        group type="text"
                                        validate
                                        required
                                        error="wrong"
                                        success="right"
                                        name="name"
                                        pattern={validate.fullName}
                                        onChange={handleChange}
                                        title="Ditt för- och efternamn"
                                    />
                                    <MDBInput
                                        label="Telefonnummer"
                                        icon="user"
                                        group
                                        type="tel"
                                        validate
                                        required
                                        error="wrong"
                                        success="right"
                                        name="phonenumber"
                                        onChange={handleChange}
                                        pattern={validate.phone}
                                        title="Ditt telefonnummer"
                                    />
                                    <MDBInput
                                        label="Email"
                                        icon="envelope"
                                        group
                                        type="email"
                                        validate
                                        required
                                        error="wrong"
                                        success="right"
                                        name="email"
                                        onChange={handleChange}
                                        pattern={validate.email}
                                        title="Din mail address"
                                    />
                                    <MDBInput
                                        label="Lösenord"
                                        icon="envelope"
                                        group type="password"
                                        validate
                                        required
                                        error="wrong"
                                        success="right"
                                        name="password"
                                        onChange={handleChange}
                                        pattern={validate.password}
                                        title="1 liten bokstav, 1 stor bokstav, 1 siffra, 1 speciell karaktär, 8-64 tecken"
                                    />
                                    <MDBInput
                                        label="Address [valfri]"
                                        icon="user"
                                        group
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        name="default_address"
                                        onChange={handleChange}
                                        title="Din hemadress"
                                    />
                                    <MDBInput
                                        label="Födelseår [valfri]"
                                        icon="user"
                                        group
                                        type="number"
                                        validate
                                        error="wrong"
                                        min={1900}
                                        max={2022}
                                        success="right"
                                        name="birthyear"
                                        onChange={handleChange}
                                        pattern={validate.birthyear}
                                        title="Ditt födelseår mellan 1900-2022"
                                    />
                                </div>

                                <div className="text-center">
                                    <MDBBtn color="danger" onClick={_ => setPage(0)}>Tillbaka</MDBBtn>
                                    <MDBBtn type="submit" onClick={_ => setIsValidated(true)}>Registrera</MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </header>
        </div>
    );
}

export default Registration;