import React, { useEffect, useState } from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';

import { TopBarContext } from 'student-pages/app';
import './ChooseSubjects.css';
import { useSubjectsData } from './subjectsData';

const colortheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: "none"
                },
                input: {
                    padding: "7px",
                    fontWeight: "500",
                    color: "#373737",
                    "&::placeholder": {
                        color: "#959595",
                        opacity: "1",
                    }
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                h3: {
                    color: '#01613B',
                    fontWeight: '500',
                    marginBottom: "0.8rem",
                    fontSize: "1.6rem",
                    textAlign: 'center'
                },
                subjectHeader: {
                    fontFamily: 'Roboto',
                    color: '#00613B',
                    fontWeight: '500',
                    marginBottom: "0.8rem",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    textTransform: "capitalize",
                    marginLeft: "clamp(1.5rem,6vw,3rem)",
                },
                subject: {
                    fontFamily: 'Roboto',
                    color: '#717171',
                    fontWeight: '400',
                    fontSize: "1.1rem",
                    textAlign: "center",
                    textTransform: "capitalize",
                    marginTop: "8px",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    transitionDuration: "200ms",
                    transitionProperty: "opacity",
                    "&.Mui-disabled": {
                        opacity: "0.75"
                    },

                    "&.Mui-disabled:hover": {
                        backgroundColor: "inherit"
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#00613b",
            light: "#99ffd7",
            dark: "#00613b"
        }
    }
});

const Subject = ({ updateChosen, icon, subject, index, formData }) => {
    const [isActive, setIsActive] = useState(formData.subjects.includes(subject));

    return (
        <ThemeProvider theme={colortheme}>
            <button key={subject} onClick={() => { updateChosen(subject, !isActive); setIsActive(!isActive) }} className={"subject-option " + (isActive ? "active" : "")}>
                <div className="subjIcon d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faCheck} size="lg" className="subjCheck" />
                    <FontAwesomeIcon icon={icon} size="5x" />
                </div>
                <Typography variant="subject" className="text-center cap">
                    {subject}
                </Typography>
            </button>
        </ThemeProvider>
    )
}
const Category = (props) => {
    return (
        <div className="subject_category">
            {
                props.subjects.map((subject, index) => {
                    return (
                        <Subject key={subject} subject={subject} index={index} {...props} />
                    )
                })
            }
            {/* Fixes padding on the right side of the subject view */}
            {/* Yes it's very ugly, but it does the work */}
            <div style={{ color: "transparent", fontSize: "1px" }}>Padding</div>
        </div>
    );
}
export const SearchBar = ({ search }) => {
    const [typed, setTyped] = React.useState();

    const handleType = e => {
        e.preventDefault();
        setTyped(e.target.value);
        try {
            search(e.target.value);
        } catch (err) {
            console.log("search", search, " doesn't work");
            console.log(err);
        }
    };

    return (
        <ThemeProvider theme={colortheme}>
            <div className='top_search_parent w-100 d-flex flex-column align-items-center'>
                <Typography variant="h3">Vad vill du ha hjälp med?</Typography>
                <TextField className={`top_search_bar f3 mb-3 px-3`} value={typed} onChange={handleType} rows="1" placeholder="Sök ämne..." />
            </div>
        </ThemeProvider>
    )
}

function ChoseSubjects(props) {
    const { formData, setFormData, step, goToNext } = props;
    const { subjectsData, search } = useSubjectsData();
    const { setTopContent } = React.useContext(TopBarContext);
    const [lastSubject, setLastSubject] = React.useState(null);

    useEffect(() => {
        setTopContent(prev => ({
            ...prev,
            childId: "SearchBar",
            childProps: { search }
        }));
        return () => setTopContent(null);
    }, [setTopContent, search]);

    const updateChosen = (title, nowChosen) => {
        setFormData(prev => {
            setLastSubject(prev.subjects.slice(-1)[0]);
            let newSubjects = prev.subjects.filter(s => s !== title);

            if (nowChosen) {
                newSubjects.push(title);
            };

            return { ...prev, subjects: newSubjects };
        })
    };

    // Show loading screen while awaiting server response (assumes at least one subject is available)
    if (!subjectsData) {
        const outerNum = 5;
        const innerNum = 10;

        return (
            <div style={{ height: "100%", overflow: "hidden" }}>
                {[...Array(outerNum)].map((e, i) =>
                    <div style={{ overflow: 'hidden' }}>
                        <Skeleton key={String(i)} variant="rounded" animation="wave" width="13rem" height="1.8rem" className='subject_header_skeleton' />
                        <div className="scrollparent">
                            <div className="subject_category" style={{ width: "max-content", maxWidth: "max-content" }}>
                                {
                                    [...Array(innerNum)].map((e, j) =>
                                        <Skeleton key={`${i} ${j}`} variant="rounded" animation="wave" width="clamp(10rem, 20vw, 15rem)" height="clamp(10rem, 20vw, 15rem)" />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <ThemeProvider theme={colortheme}>
                <section className="d-flex flex-column align-items-center choose-subjects-section pt-2">
                    <div>
                        {
                            subjectsData && subjectsData.data.map((subjectData) => {
                                return <div key={subjectData.title} className="category_wrapper mt-3 mb-2">
                                    <Typography variant="subjectHeader">{subjectData.title}</Typography>
                                    <div className="scrollParent">
                                        <Category updateChosen={updateChosen} formData={formData} {...subjectData} />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </section>

                <div className="position_select_root" style={{ bottom: !!formData.subjects.length ? "2%" : "-20%", display: !!formData.subjects.length ? "flex" : "flex" }}>
                    <div className="position_select_size_wrapper">
                        <div className="position_select_panel">
                            <div className="position_select_info">
                                <Typography className="address_text">
                                    {formData.subjects.slice(-1)[0] || lastSubject}
                                </Typography>
                                <Typography className="coordinates_text">
                                    Totalt {!!formData.subjects.length ? formData.subjects.length : 1} {formData.subjects.length <= 1 ? "vald kurs" : "valda kurser"}
                                </Typography>
                            </div>
                            <Button onClick={goToNext} className="next_button">Nästa</Button>
                        </div>
                    </div>
                </div>

                {!!formData.subjects.length ?
                    <div className="position_select_root" style={{ opacity: 0, position: 'unset', paddingBottom: "2vh" }}>
                        <div className="position_select_size_wrapper">
                            <div className="position_select_panel">
                                <div className="position_select_info">
                                    <Typography className="address_text">
                                        a
                                    </Typography>
                                    <Typography className="coordinates_text">
                                        a
                                    </Typography>
                                </div>
                                <Button onClick={goToNext} className="next_button">Nästa</Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ height: "1rem" }} />
                }

            </ThemeProvider >
        )
    }

}

export default ChoseSubjects;