import React, { useContext } from 'react';

import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpIcon from '@mui/icons-material/Help';

import { LocationSearchHelper } from 'student-pages/add-booking/ChooseLocation';
import { SearchBar } from 'student-pages/add-booking/ChooseSubjects';
import { TimeDateTitle } from 'student-pages/add-booking/ChooseTimeDate';
import { TopBarContext } from 'student-pages/app';
import { HomeTitle } from 'student-pages/home';

import styles from './styles.module.css';

// Map of components that can be displayed in the TopBar component
const ContentMap = {
    SearchBar,
    HomeTitle,
    LocationSearchHelper,
    TimeDateTitle,
};

// The TopBar component is a functional component that displays a navbar at the top of the screen
function TopBar(props) {
    const { topContent, step, setStep, totalSteps } = useContext(TopBarContext);
    const { childId, childProps, childPos, flatTop } = topContent || {};
    const Child = ContentMap[childId];
    return (
        <>
            <div className={styles.parent}>
                <nav className={`navbar sticky-top ${styles.navbar}`} >
                    <div className="container-fluid w-100 justify-content-between align-items-center" style={{ margin: "0.3rem 0" }}>
                        <FontAwesomeIcon
                            className={styles.back_button}
                            onClick={() => { setStep(step - 1); }}
                            icon={faLongArrowAltLeft}
                            style={{ opacity: (!!step && step < (totalSteps)) ? 1 : 0 }} />
                        <HelpIcon className={`mb-1 ${styles.help_button}`} />
                    </div>
                    {(childId in ContentMap && childPos !== 'bottom') && <Child {...childProps} />}
                    {/* Smooth edge */}
                    {
                        flatTop !== true &&
                        <div className={`${styles.decoration_edge}`}>
                            <div className={`${styles.background_board}`} />
                            <div className={`${styles.whiteboard}`} />
                        </div>
                    }
                </nav>
            </div>
            <div className={`${styles.bottom_box}`}>
                {(childId in ContentMap && childPos === 'bottom') && <Child {...childProps}></Child>}
            </div>
        </>
    )
};

export default TopBar;
