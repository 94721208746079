import React, { useContext } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput, MDBIcon } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

import { LoginContext } from 'app'
import { timeStrToSwe, dateStrToShortSwe, formatTimeDelta } from 'services/formats/dates';

import styles from './coming.module.css'

function CoomingLesson({ lesson, onClick }) {
    //API skickar inte namn på lärare just nu, behöver fixas!
    const { role } = useContext(LoginContext)
    const name = role === "student" ? lesson.teacher.name : lesson.student.name
    return (
        <article className={`${styles.info} p-3 mb-5`} onClick={onClick}>
            <MDBContainer fluid>
                <MDBRow className="d-flex justify-content-center">
                    <MDBCol size="6" className="d-flex flex-column justify-content-center">
                        <p className={`f2 cap`}>{name}</p>
                        <p className={`f3`}>Kl. {timeStrToSwe(lesson.start_time)}</p>
                        <p className={`f3`}>{dateStrToShortSwe(lesson.start_time)}</p>
                    </MDBCol>
                    <MDBCol size="6" className="d-flex flex-column justify-content-center">
                        {/* <p className="f3">{lesson.length} min</p> */}
                        <span className={`${styles.time_box} f3 p-1 text-center`}>
                            {formatTimeDelta(new Date(), lesson.start_time)}kvar
                        </span>
                        <a href={`http://www.google.com/maps/place/${lesson.position.lat},${lesson.position.lng}`} target="_blank" className={`text-center mt-1 f3`}>
                            <FontAwesomeIcon className={styles.map_marker} icon={faMapMarkedAlt}></FontAwesomeIcon> <span className={`${styles.pos_link}`}>{lesson.position.adress}</span>
                        </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </article>
    )
}
export default CoomingLesson