import {subscribeUser} from 'services/notifications/push-subscription'


export async function showNotification(){
    const res = await Notification.requestPermission();
    console.log("Notires", res)
    if(res === "granted"){
        const noti = new Notification("Hello From GL App", {
            body: "I'm a push notification",
            icon: "/logo192.png"
        })
    }
}

export async function requestPerm(){
    console.log("trying to request permission...");
    const res = await Notification.requestPermission();
    console.log("Permission was ", res);
    if(res !== "granted")
        return res;
    await subscribeUser();
    return res;
}