import React from 'react'
// import {useSwipeable} from 'react-swipeable'
import {useSpring, animated} from 'react-spring'
import {useDrag} from '@use-gesture/react'

import New from '../SwipeCard'


function Exp() {
    const [deltXY, setDeltXY] = React.useState([0,0]);
    const [deltX, setDeltX] = React.useState(0);
    const myRef = React.useRef();
    React.useEffect(()=>{
        console.log(myRef.current?.offsetWidth, myRef.current);
        
    })
//     const handlers = useSwipeable({
//         onSwiping: e => {

//             // alert(`swipede ${e.deltaX} ${e.deltaY}`);
//             setDeltXY([e.deltaX, e.deltaY]);
//             setDeltX(e.deltaX);
// //            console.log("Swiped", e, e.deltaX, e.deltaY);
//         },
//         onSwiped: e=>{
//             setDeltX(0);
//             setDeltXY([0,0]);
//         }
//     })
//     return (
//         <div className={`${styles.cont}`} {...handlers} style={{transform: `translate(${deltXY[0]}px, ${deltXY[1]}px)`}}>
//             <div ref={myRef}>
//                 Nested div
//             </div>
//             Test Swipe
//         </div>
//     )
    return <div>
        Outdated
    </div>
}

export default New
