import React from 'react'

import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api'

import styles from './maps.module.css'

const API_KEY = "AIzaSyDpOmk71srDXY9hth_JSMuppGIx2R9-xXo"

//console.log(GoogleMap)

const containerStyle = {
    width: 'var(--large-w)',
    height: 'var(--tall-h)',
    margin: 'auto'
};

const center = {
  lat: 55.8221856797613,
  lng: 9.6128143387
};

function Maps() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  })

  const [map, setMap] = React.useState(null)
  const [markers, setMarkers] = React.useState([])

  const addMarker = React.useCallback(e=>{
    setMarkers(prev=>[...prev, {lat: e.latLng.lat(), lng: e.latLng.lng()}]);
  }, [])
  
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  return isLoaded ? (
      <GoogleMap
        // mapContainerClassName={styles.mapContainer}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={addMarker}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
        // onClick={e=>console.log('MapEvent', e, e.latLng.lat())}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker position={center}></Marker>
        {
          markers.map(pos=><Marker position={pos} />)
        }
      </GoogleMap>
  ) : <></>
}
  

export default Maps
