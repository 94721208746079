import React from 'react'
import matteImg from './subjImgs/matte.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlask,
    faSquareRootVariable,
    faDna,
    faAtom,
    faArrowTrendUp,
    faBalanceScale,
    faHandshake,
    faLandmark,
    faLightbulb,
    faBrain,
    faBookOpen,
    faPrayingHands,
    faMagnet,
    faPenNib,
    faCode,
    faGlobe
} from '@fortawesome/free-solid-svg-icons';


// const defaultImgData = {
//     imgSrc: "https://images.unsplash.com/photo-1576319155264-99536e0be1ee?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGh5c2ljc3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
//     imgType: subjectsData.imgTypes.STANDING
// };
// const imgMap = {
//     matte: {
//         imgSrc: matteImg,
//         imgType: subjectsData.imgTypes.LYING
//     }
// };


const iconMap = {
    matematik: faSquareRootVariable,
    svenska: faPenNib,
    engelska: faGlobe,
    teknik: faCode,
    kemi: faFlask,
    biologi: faDna,
    fysik: faAtom,
    ekonomi: faArrowTrendUp,
    juridik: faBalanceScale,
    naturvetenskap: faMagnet,
    samhällsvetenskap: faHandshake,
    historia: faLandmark,
    religion: faPrayingHands,
    filosofi: faLightbulb,
    psykologi: faBrain,
}

const defaultIcon = faBookOpen;

const fetchAll = async () => {
    const staticSubjectsData = { //static = not state
        imgTypes: {
            STANDING: "standing",
            LYING: "lying"
        },
        data: []
    };

    const res = await fetch("/api/lesson/student/subjects-list");
    const { subjects: fetchedSubjects } = await res.json();

    for (let category in fetchedSubjects) {
        if (category) {
            staticSubjectsData.data.push({
                title: category,
                icon: iconMap[category] || defaultIcon,
                subjects: fetchedSubjects[category],
            })
        }
    };

    return staticSubjectsData;
};

export const useSubjectsData = () => {
    const [fullData, setFullData] = React.useState(null);
    const [subjectsData, setSubjectsData] = React.useState();

    React.useEffect(() => {
        fetchAll().then(fetched => {
            setFullData(fetched);
            setSubjectsData(fetched);
        });
    }, []);

    const search = React.useCallback(async (search_str) => {
        //Updates subjectsData state to contain only matching categories with subjects that match
        let newData = [];
        if (fullData === null)
            return;
        // const fullDataLocal = await (async ()=>{
        //     if(fullData === null)
        //         return fetchAll();
        //     return fullData;
        // })()
        const fullDataLocal = fullData;
        for (let categoryData of fullDataLocal.data) {
            let matches = [];
            for (let subject of categoryData.subjects) {
                if (subject.toLowerCase().search(search_str?.toLowerCase()) !== -1) {
                    matches.push(subject);
                }
            }
            if (matches.length > 0)
                newData.push({
                    ...categoryData,
                    subjects: matches
                });
        }
        setSubjectsData(prev => ({
            ...prev,
            data: newData
        }));
    }, [setSubjectsData, fullData]);

    return {
        subjectsData,
        search
    }
}