import ResetPassword from 'components/ResetPassword';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'bootstrap/dist/css/bootstrap.min.css';
import "mdbreact/dist/css/mdb.css";

import './index.css';

const Routing = () => {
    // return (
    //     <h1>This is not nothing!!!</h1>
    // )
    return (
        <Router>
            <Routes>
                <Route path="/reset-password/:code" element={<ResetPassword />}></Route>
                <Route path="/*" element={<App />}></Route>
            </Routes>
        </Router>
    )
}


ReactDOM.render(
    <React.StrictMode>
        <Routing></Routing>
    </React.StrictMode>,
    document.getElementById('root')
);

console.log("PUBLIC KEY", process.env.REACT_APP_PUBLIC_VAPID_KEY)
serviceWorkerRegistration.register();
//subscribeUser();
//renew_sub();
reportWebVitals();