import React, {useContext} from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import {useLogin} from 'hooks/fetch-hooks'
import Loading from 'components/Loading'
import Auth from 'components/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {LoginContext} from 'app'
//Profile Pages
import Edit from './edit-profile'

import "./Profile.css"


function ProfileInfo() {
    const {profile} = useContext(LoginContext);
    return (
        <section className="profile-info">
            <MDBContainer fluid>
                <MDBRow>
                    <MDBCol md="8 col-centered">
                        <header className="d-flex flex-column justify-content-center align-items-center mb-3">
                            <FontAwesomeIcon icon={faUser} size="5x"></FontAwesomeIcon>
                            <p className="cap">{profile.name}</p>
                        </header>
                        {
                            pages.map(page => {
                                const to = page.link || `${page.title}`;
                                return <div key={page.title} className="profile-link text-center cap py-3">
                                    <Link to={to}>{page.title}</Link>
                                </div>
                            })
                        }
                        {/* <button className="btn btn-primary w-100" onClick={changeLink} >Edit Profile</button> */}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

        </section>
    )
}

const Temp = ()=>{
    return <h1 className="f1">This page is under construction...</h1>
}

//Kanske flytta data till annan fil
const pages = [
    {
        title: "Uppdatera profil",
        link: "edit-profile",
        component: Edit
    },
    {
        title: "Favoriter",
        link: "favorites",
        component: Temp
    },
    {
        title: "Bjud in vänner",
        link: "send-invite",
        component: Temp
    },
    {
        title: "Inställningar",
        link: "settings",
        component: Temp
    },
    {
        title: "Hjälp",
        link: "help",
        component: Temp
    },
    {
        title: "Användarvillkor",
        link: "terms",
        component: Temp
    }
];
function Main () {
    const {signed_in, setSigned_in, type} = useLogin();

    if(signed_in === null) return <Loading></Loading>
    if(signed_in === false) return <Auth setSigned_in={setSigned_in}></Auth>

    return (
        <Routes>
            {
                pages.map(page=><Route path={page.link || `${page.title}`} element={<page.component/>} key={page.title}></Route>)
            }
            <Route path="/" element={<ProfileInfo/>}></Route>
        </Routes>
    )
}
export default Main
