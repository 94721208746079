import React, { useState } from 'react';
import Maps from 'components/Maps';
import { getGeoData } from 'components/Maps/geocoder';
import { useFetch } from 'hooks/fetch-hooks';
import { TopBarContext } from 'student-pages/app';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import styles from "./styles.module.css";

import { createTheme } from '@mui/material';

const Location = ({ title, position, onClick, disabled = false }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${styles.saved_loc} ${styles.saved_loc_button}`}
      style={{ opacity: disabled ? 0.6 : 1 }}>
      <p className={`text-center`}>{title || position.adress}</p>
    </button >
  )
};

export const LocationSearchHelper = ({ setPosition, setCenter }) => {
  const { data: savedPos, isLoading } = useFetch("/api/lesson/student/used-pos", { authorize: true });
  const [filtered, setFiltered] = useState(null);
  const [typed, setTyped] = React.useState("");
  const [validSearch, setValidSearch] = React.useState(true);

  const colortheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "none",
          },
          root: {
            paddingRight: "0",
          },
          input: {
            padding: "7px",
            fontWeight: "500",
            color: validSearch ? "#373737" : "#df4759",
            "&::placeholder": {
              color: "#959595",
              opacity: "1",
            }
          },
        }
      },
      MuiTypography: {
        styleOverrides: {
          h3: {
            color: '#01613B',
            fontWeight: '500',
            marginBottom: "0.8rem",
            fontSize: "1.6rem",
            textAlign: 'center'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            transitionDuration: "200ms",
            transitionProperty: "opacity",
            "&.Mui-disabled": {
              opacity: "0.75"
            },

            "&.Mui-disabled:hover": {
              backgroundColor: "inherit"
            }
          }
        }
      }
    },
    palette: {
      primary: {
        main: "#00613b",
        light: "#99ffd7",
        dark: "#00613b"
      }
    }
  });

  React.useEffect(() => {
    if (!setFiltered || !savedPos)
      return
    setFiltered(savedPos.filter(pos => {
      return pos.adress?.toLowerCase().search(typed.toLowerCase()) > -1;
    }))
    return () => setFiltered(savedPos);
  }, [setFiltered, typed, savedPos]);
  const handleChange = async e => {
    e.preventDefault();
    setTyped(e.target.value);
    setValidSearch(true);
  };

  const handleKeyPress = async e => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.preventDefault();
      searchPosition(e.target.value);
    }
  };

  async function searchPosition(address) {
    const foundPos = await getGeoData(address);
    if (foundPos) {
      const { lat, lng } = foundPos;
      setPosition(foundPos);
      setCenter({ lat, lng });
      setValidSearch(true);
      setTyped("");
      document.activeElement.blur();
    } else {
      setValidSearch(false);
    }
  };

  return (
    <ThemeProvider theme={colortheme}>
      <div className='d-flex flex-column align-items-center w-100'>
        <Typography variant="h3">Vilken plats vill du plugga på?</Typography>

        <TextField
          className={`top_search_bar f3 mb-3 px-3 ${validSearch ? "" : styles.error}`}
          error={validSearch}
          value={typed}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          rows="1"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  className={!!typed ? "secondary-dark" : "dark"}
                  style={{ transition: "color 200ms" }}
                  onClick={_ => searchPosition(typed)} />
              </InputAdornment>
            ),
          }}
          placeholder="Sök plats..." />

        <div className={styles.saved_loc_parent}>
          {isLoading ?
            <>
              <Skeleton variant="rounded" animation="wave" width="16rem" className={styles.saved_loc} />
              <Skeleton variant="rounded" animation="wave" width="8rem" className={styles.saved_loc} />
              <Skeleton variant="rounded" animation="wave" width="25rem" className={styles.saved_loc} />
            </>
            :
            <>
              {
                savedPos.map(loc => {
                  return (
                    <Location
                      key={String(loc.id) + loc.adress}
                      disabled={!filtered.includes(loc)}
                      position={loc}
                      onClick={_ => { if (filtered.includes(loc)) { setPosition(loc); setCenter(loc) } }} />
                  )
                })
              }
            </>
          }
        </div>
      </div>
    </ThemeProvider>
  )
}

function ChooseLocation({ formData, setFormData, goToNext }) {
  const [position, setPosition] = useState(formData.position);
  const [center, setCenter] = useState();
  const { setTopContent } = React.useContext(TopBarContext);
  React.useEffect(() => {
    setTopContent(prev => ({
      ...prev,
      flatTop: true,
      childId: 'LocationSearchHelper',
      childProps: {
        setCenter,
        setPosition
      }
    }))
    return () => {
      setTopContent(prev => ({
        ...prev,
        childId: null,
        childProps: {},
        flatTop: false
      }))
    }
  }, [setTopContent])
  React.useEffect(() => {
    setFormData(prev => ({
      ...prev,
      position
    }));
  }, [position, setFormData]);

  return (<>
    <Maps position={formData.position} setPosition={setPosition} center={center} goToNext={goToNext}></Maps>
  </>)

}

export default ChooseLocation
