import React, { useContext } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import {useNavigate} from 'react-router-dom'
import Loading from 'components/Loading'

import {teacherLinks} from 'components/navigation/teacher-navigation'
import { LoginContext } from 'app'
import "./style.css"

export function ProfileForm() {

    const navigate = useNavigate();
    const {profile, setProfile, setSigned_in} = React.useContext(LoginContext)

    const handleChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value.trim()
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        async function fetchData() {
            fetch('/api/profile/update', { body: JSON.stringify(profile), method: 'POST', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token'), 'content-type': "application/json" } })
                .then(async r=>{
                    const newData = await r.json();
                    console.log(r, newData);
                    setProfile({
                        ...profile,
                        ...newData
                    })
                })
                .catch(err => alert(err));
        }
        fetchData();
        navigate(teacherLinks.PROFILE)
    };
    if(!profile)
        return <Loading></Loading>
    return (
        <div className="Profile">
            <header className="Profile-header">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="6 col-centered">
                            <form>
                                <p className="f2 mb-4 text-center">Mina uppgifter</p>
                                <div className="grey-text">
                                    <MDBInput label="Name" icon="user" group type="text" validate error="wrong"
                                        success="right" name="name" valueDefault={profile.name} onChange={handleChange} />
                                    <MDBInput label="Phone Number" icon="user" group type="phone" validate error="wrong"
                                        success="right" name="phoneNumber" valueDefault={profile.phoneNumber} onChange={handleChange} />
                                    <MDBInput label="Birthday" icon="user" group type="date" validate error="wrong"
                                        success="right" name="birthday" valueDefault={profile.birthday} onChange={handleChange} />
                                    <MDBInput label="Address" icon="user" group type="text" validate error="wrong"
                                        success="right" name="address" valueDefault={profile.address} onChange={handleChange} />
                                </div>
                                <div className="text-center">
                                    <MDBBtn className="profileButton" onClick={handleSubmit}>Update Profile</MDBBtn>
                                </div>
                                <div className="text-center">
                                    <div className="text-center">
                                        <MDBBtn className="profileButton" onClick={_ => { localStorage.clear(); setSigned_in(false); }}>Sign out</MDBBtn>
                                    </div>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </header>
        </div>
    );
}
export default ProfileForm;