import { createTheme } from '@mui/material';

const colortheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    transitionDuration: "200ms",
                    transitionProperty: "opacity",
                    "&.Mui-disabled": {
                        opacity: "0.75"
                    },

                    "&.Mui-disabled:hover": {
                        backgroundColor: "inherit"
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#00613b",
            light: "#99ffd7",
            dark: "#00613b"
        }
    }
});

export default colortheme