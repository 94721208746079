import { createTheme } from '@mui/material';

const colortheme = createTheme({
    components: {
        PrivatePickersYear: {
            styleOverrides: {
                button: {
                    color: "#2A2A2A",

                    "&.Mui-disabled": {
                        color: "#959595",
                        cursor: "default"
                    },

                    "&.Mui-disabled:hover": {
                        backgroundColor: "inherit"
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h5: {
                    color: "#00613b",
                    marginTop: "1rem",
                    marginBottom: "0.25rem",
                    fontWeight: "500",
                },
                h3: {
                    color: '#01613B',
                    fontWeight: '500',
                    marginBottom: "0.3rem",
                    fontSize: "1.6rem",
                    textAlign: 'center'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    color: "#00613b",
                    marginTop: "1.5rem",
                    backgroundColor: "#C1C1C1",
                    height: "2px !important"
                }
            }
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    backgroundColor: "#E5E5E5",
                    borderRadius: "10px"
                }
            }
        },
        MuiDayPicker: {
            styleOverrides: {
                weekDayLabel: {
                    color: "#01613B",
                    fontSize: "15px",
                    fontWeight: "500"
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    backgroundColor: "#E5E5E5",

                    "&.Mui-selected": {
                        color: "#fcfaf9",
                        backgroundColor: "#00613b"
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    backgroundColor: "#C1C1C1",
                    borderRadius: "10px",
                    padding: "3px 10px",
                    textAlign: "center",
                    width: "50px",
                    color: "#2A2A2A",
                    fontSize: "1.2rem"
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#00613b",
            light: "#99ffd7",
            dark: "#00613b"
        }
    }
});

export default colortheme