import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";

import {customPost} from 'services/api/promises'

import styles from './promos.module.css'

function CreatePromo() {
    const [formData, setFormData] = React.useState({
        discount_percentage: 10,
        discount_amount: 0,
        amount: 1,
        valid_for: 3600*24*365, //1 YEAR
    });
    // [amount, setAmount] = React.useState(1);
    // [discount_percentage, setDiscountPercentage] = React.useState(10);
    // [discount_amount, setDiscountAmount] = React.useState(0);
    // [valid_for, setValidFor] = React.useState(3600*24*365); // 1 YEAR
    const [created, setCreated] = React.useState([]);
    const handleChange = (e)=>{
        e.preventDefault();
        if(e.target.value < 0)
            return;
        setFormData(prev=>({
            ...prev,
           [e.target.name]:e.target.value,
        }))
    }
    const handleSubmit = async ()=>{
        if(!window.confirm(`vill du skapa ${formData.amount} promo koder med ${formData.discount_percentage}% rabbatt.`))
            return;
        const {discount_percentage, discount_amount, valid_for, amount} = formData;
        const res = await customPost("/api/admin/create-promo", formData, {}).catch(err=>alert(err));
        if(!res.ok){
            alert(res.status, res.statusText)
        }
        const parsed = await res.json();
        alert(parsed?.msg);
        setCreated(parsed.promos)
    }
    return (
        <section className='text-center'>
            <h1>Skapa Promo</h1>
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12 col-centered">
                            <form>
                                <div>
                                    <MDBInput label="Rabbat (%)"  group type="number" validate error="wrong"
                                        success="right" name="discount_percentage" onChange={handleChange} value={formData.discount_percentage} />
                                </div>
                                <div>
                                    <MDBInput label="Discount (kr)"  group type="number" validate error="wrong"
                                        success="right" name="discount_amount" onChange={handleChange} value={formData.discount_amount} />
                                </div>
                                <div>
                                    <MDBInput label="Antal (st)"  group type="number" validate error="wrong"
                                        success="right" name="amount" onChange={handleChange} value={formData.amount} />
                                </div>
                                <div>
                                    <MDBInput label="Giltighetstidl (sekunder)"  group type="number" validate error="wrong"
                                        success="right" name="amount" onChange={handleChange} value={formData.valid_for} />
                                </div>


                                <div className="text-center">
                                    <MDBBtn className={styles.login_btn} onClick={handleSubmit}>Skapa Koder</MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                        { created?.length && <MDBCol className='my-5'>
                            <h2>Skapade Koder</h2>
                            {
                                created.map(code=>(
                                    <p>{code}</p>
                                ))
                            }
                        </MDBCol>}
                </MDBRow>
            </MDBContainer>
        </section>
    )
}

export default CreatePromo
