import React from 'react'

import styles from './customcontainer.module.css'

console.log("custCont", styles)

function CustomContainer({children, skipContainer}) {
    if(skipContainer === true)
        return <>
            {
                children
            }
        </>
    return (
        <div className={`${styles.custom_container}`}>
            {
                children
            }
        </div>
    )
}

export default CustomContainer
