import React, { useContext } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import { useNavigate } from 'react-router-dom'
import Loading from 'components/Loading';
import validate from 'formValidation.js';

import { studentLinks } from 'components/navigation/student-navigation';
import { LoginContext } from 'app';
import "./style.css";

// ProfileForm component to update user profile data
export function ProfileForm() {
    // Navigate hook from react-router-dom
    const navigate = useNavigate();
    // Access context to get/set user data
    const { profile, setProfile, setSigned_in } = React.useContext(LoginContext);
    // Use state to store form data
    const [newProfile, setNewProfile] = React.useState({ ...profile });
    // Ref for form element for validation
    const formRef = React.useRef(null);
    // State to store validation status
    const [isValidated, setIsValidated] = React.useState(false);

    // Update form data on change
    const handleChange = (e) => {
        setNewProfile({
            ...newProfile,
            [e.target.name]: e.target.value.trim()
        });
    }

    // Submit form data to API and update context
    const handleSubmit = (e) => {
        e.preventDefault();

        if (formRef.current.checkValidity()) {
            fetch('/api/profile/update', {
                body: JSON.stringify(newProfile),
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'content-type': "application/json"
                }
            })
                .then(async res => {
                    if (!res.ok) {
                        res.json().then(json => { alert(json.detail[0].msg) });

                    } else {
                        const newData = await res.json();
                        setProfile({
                            ...profile,
                            ...newData
                        })
                        navigate(studentLinks.PROFILE);
                    };
                })
                .catch(err => alert(err));
        }
    };

    // Show loading component while data is loading
    if (!profile)
        return <Loading></Loading>

    return (
        <div className="Profile">
            <header className="Profile-header">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="6 col-centered">
                            <form ref={formRef} className={`${isValidated ? 'was-validated' : 'needs-validation'}`} onSubmit={handleSubmit}>
                                <p className="f2 mb-4 text-center">Mina uppgifter</p>
                                <div className="grey-text">
                                    <MDBInput
                                        label="För- och efternamn"
                                        valueDefault={newProfile.name}
                                        icon="user"
                                        group type="text"
                                        validate
                                        required
                                        error="wrong"
                                        success="right"
                                        name="name"
                                        pattern={validate.fullName}
                                        onChange={handleChange}
                                        title="Ditt för- och efternamn"
                                    />
                                    <MDBInput
                                        label="Telefonnummer"
                                        valueDefault={newProfile.phonenumber}
                                        icon="user"
                                        group
                                        type="tel"
                                        validate
                                        required
                                        error="wrong"
                                        success="right"
                                        name="phonenumber"
                                        onChange={handleChange}
                                        pattern={validate.phone}
                                        title="Ditt telefonnummer"
                                    />
                                    <MDBInput
                                        label="Address"
                                        valueDefault={newProfile.default_address}
                                        icon="user"
                                        group
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        name="default_address"
                                        onChange={handleChange}
                                        title="Din hemadress"
                                    />
                                    <MDBInput
                                        label="Födelseår"
                                        valueDefault={newProfile.birthyear}
                                        icon="user"
                                        group
                                        type="number"
                                        validate
                                        error="wrong"
                                        min={1900}
                                        max={2022}
                                        success="right"
                                        name="birthyear"
                                        onChange={handleChange}
                                        pattern={validate.birthyear}
                                        title="Ditt födelseår mellan 1900-2022"
                                    />
                                </div>
                                <div className="text-center">
                                    <MDBBtn type="submit" onClick={_ => setIsValidated(true)}>Uppdatera profil</MDBBtn>
                                </div>
                                <div className="text-center">
                                    <div className="text-center">
                                        <MDBBtn
                                            className="profileButton"
                                            color="danger"
                                            onClick={_ => { localStorage.clear(); setSigned_in(false); navigate("/") }}>
                                            Logga ut
                                        </MDBBtn>
                                    </div>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </header>
        </div>
    );
}
export default ProfileForm;