import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetch} from 'hooks/fetch-hooks'
import Loading from 'components/Loading'
import {studentLinks} from 'components/navigation/student-navigation'

import Base from './base'

import styles from './schedule.module.css'

const Empty = ()=>{
    const navigate = useNavigate();
    return (
        <div className='big-info'>
            <h3 className='f3'>Inga Lektioner?</h3>
            <button className="btn btn-default f3 cap px-5" onClick={()=>{ navigate(studentLinks.BOOK)}}>Boka nu</button>
        </div>
    )
}

function StudentSchedule({debugData}) {
    const data = useFetch("/api/lesson/student/scheduled", {authorize: true});
    if(!data?.isLoading === false)
        return <Loading></Loading>
    //For debug ONLY Remove in production'
    // !!! ---===---
    if(debugData && data?.data.length === 0)
        return (
        <section className={`${styles.schedule}`}>
            <Base data={debugData} isLoading={false}></Base>
        </section>
        )
    if(data?.data.length === 0)
        return <Empty></Empty>
    return (
        <section className={`${styles.schedule}`}>
            <Base {...data}></Base>
        </section>
    )
}

export default StudentSchedule
