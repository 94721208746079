import React from 'react';

import { ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { getAddress } from './geocoder';
import styles from './maps.module.css';
import colortheme from "./mui-theme";

const API_KEY = "AIzaSyDpOmk71srDXY9hth_JSMuppGIx2R9-xXo";


// The containerStyle object defines the dimensions of the map container element
const containerStyle = {
  width: '100vw',
  height: '100%'
};

// The defaultCenter object represents the default center point of the map
const defaultCenter = {
  lat: 59.3459867,
  lng: 18.08
};

// The Maps component takes in the following props:
// - position: an object representing the latitude and longitude coordinates of a location to be displayed on the map
// - setPosition: a function for updating the position state
// - center: an object representing the center point of the map
// - readOnly: a boolean indicating whether the map is read-only (i.e., the user cannot select a new location)
// - goToNext: a function for navigating to the next step in the form
function Maps({ position, setPosition = () => { }, center, readOnly, goToNext }) {
  // Use the useJsApiLoader hook to asynchronously load the Google Maps JavaScript API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  });

  // Initialize the fetchingLocation state, which will be used to indicate whether the app is currently fetching the address for the selected location
  const [fetchingLocation, setFetchingLocation] = React.useState(false);

  // The handleClick function will be called when the map is clicked
  const handleClick = React.useCallback(async e => {
    if (readOnly) {
      return
    };

    // Extract the latitude and longitude from the event object
    const newLatLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };

    // Update the position state with the new latitude and longitude
    setPosition(prev => ({
      ...prev,
      ...newLatLng
    }));

    // Set the fetchingLocation state to true to indicate that the app is currently fetching the address
    setFetchingLocation(true);

    // Record the start time for the address fetching process
    const startTimestamp = performance.now();

    // Fetch the address for the new location
    const adress = await getAddress(newLatLng);

    // Update the position state with the new address
    setPosition(prev => ({
      ...prev,
      adress
    }));

    // Make sure the animation continues for at least 300ms
    // because finished animations are very sexy
    const waitTime = Math.max(300 - Number(performance.now() - startTimestamp), 0);
    setTimeout(() => {
      // Set the fetchingLocation state to false to indicate that the address fetching process has completed
      setFetchingLocation(false);
    }, waitTime)
  }, [readOnly, setPosition, setFetchingLocation]);

  // The validUserSelection variable will be used to determine whether the user has made a valid selection on the map
  const validUserSelection = !(fetchingLocation || !position);

  // If the Google Maps JavaScript API has finished loading, render the map and related components
  return isLoaded ? (
    <ThemeProvider theme={colortheme}>
      {/* Render the GoogleMap component, passing in the following props:
    - mapContainerStyle: an object defining the style of the map container element
    - center: the center point of the map
    - zoom: the zoom level of the map
    - onClick: the function to be called when the map is clicked
    - options: an object containing options for the map */}

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center || defaultCenter}
        zoom={10}
        onClick={handleClick}
        options={{ disableDefaultUI: true }}
      >
        { /* Child components, such as markers, info windows, etc. */}
        {
          position && <Marker position={position}>
          </Marker>
        }
        {
          position && (
            <div className={styles.position_select_root}>
              <div className={styles.position_select_size_wrapper}>
                <div className={styles.position_select_panel}>
                  <div className={styles.position_select_info}>
                    {
                      fetchingLocation ? (
                        <Skeleton variant="rounded" animation="wave" height={25} width="95%" className={styles.loading_placeholder} />
                      ) : (
                        <Fade in={!fetchingLocation} timeout={500}>
                          <Typography className={styles.address_text}>
                            {position?.adress || 'Okänd adress'}
                          </Typography>
                        </Fade>
                      )
                    }
                    <Typography className={styles.coordinates_text}>
                      {`${position?.lat.toFixed(8)}, ${position?.lng.toFixed(8)}`}
                    </Typography>
                  </div>
                  <Button onClick={goToNext} disabled={!validUserSelection} className={styles.next_button}>Nästa</Button>
                </div>
              </div>
            </div>
          )
        }
      </GoogleMap >
    </ThemeProvider>
  ) : <></>
}


export default Maps;
