import React from 'react'

import CoomingLesson from 'components/LessonDisplay/ComingLesson'

import styles from './schedule.module.css'

function LessonList({data: coomingLessons, isLoading}) {
    return (
            <div className={`${styles.data} px-3`}>
                {
                    !isLoading && coomingLessons?.map(lesson=>{
                        return (
                            <CoomingLesson key={lesson.id} lesson={lesson}></CoomingLesson>
                        )
                    })
                }
            </div>
    )
}

export default LessonList
