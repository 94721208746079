import React from 'react';
import { Routes, Route } from "react-router-dom";

import Navigation, {teacherLinks} from 'components/navigation/teacher-navigation'
import CustomContainer from 'components/CustomContainer'
import Home from './home'
import Flow from './booking-flow'
import History from './history'
import Profile from './profile'

function Routing() {
    console.log(teacherLinks);
    return (
        <>
            <Navigation></Navigation>
            <Routes>
                <Route path={teacherLinks.HOME}
                  element={<CustomContainer><Home/></CustomContainer>}
                />
                <Route path={teacherLinks.FLOW}
                  element={<CustomContainer><Flow/></CustomContainer>}
                />
                <Route path={teacherLinks.HISTORY}
                    element={<CustomContainer><History/></CustomContainer>}
                />
                <Route path={teacherLinks.PROFILE+"/*"}
                  element={<CustomContainer><Profile/></CustomContainer>}
                />
            </Routes>
        </>
    )
}

export default Routing
