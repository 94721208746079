import React, { useEffect, useState } from 'react';

// Import various mui components
import { ThemeProvider } from '@mui/material';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Import swedish locale for language specification
import svLocale from 'date-fns/locale/sv';

// Import various custom components
import { LessonDateSelect, LessonLengthInput, TimeBetweenSelect, TotalSum } from "./components.js";
import './index.css';
import colortheme from "./mui-theme.js";
import {TopBarContext} from 'student-pages/app';

export const TimeDateTitle = () => {
    return (
        <ThemeProvider theme={colortheme}>
            <div className='top_search_parent w-100 d-flex flex-column align-items-center'>
                <Typography variant="h3">Välj datum & tid</Typography>
            </div>
        </ThemeProvider>
    )
}


// The main component
function ChooseDate({ formData, setFormData, goToNext }) {
    const {setTopContent} = React.useContext(TopBarContext);

    // Setting up the component's state using the useState hook
    const [lessonDate, setLessonDate] = useState(formData.date);
    const [earliestStartTime, setEarliestStartTime] = useState(
        formData.earliest_potential_start
    );
    const [latestStartTime, setLatestStartTime] = useState(
        formData.latest_potential_start
    );
    const [lessonHours, setLessonHours] = useState(1);
    const [lessonMinutes, setLessonMinutes] = useState(0);

    // Setting up an effect to update the form data with the calculated lesson length
    useEffect(() => {
        setFormData((formData) => ({
            ...formData,
            length: lessonMinutes + 60 * lessonHours
        }));
    }, [setFormData, lessonHours, lessonMinutes]);


    useEffect(() => {
        setTopContent(prev => ({
            ...prev,
            childId: "TimeDateTitle",
            childProps: {  }
        }));
        return () => setTopContent(null);
    }, [setTopContent])


    // Setting up an effect to update the form data with the latest potential start time
    useEffect(() => {
        const baseDate = new Date(lessonDate.getTime());

        baseDate.setHours(
            latestStartTime.getHours(),
            latestStartTime.getMinutes(),
            0
        );

        setFormData((formData) => ({
            ...formData,
            latest_potential_start: baseDate
        }));
    }, [setFormData, latestStartTime, lessonDate]);

    // Setting up an effect to update the form data with the earliest potential start time
    useEffect(() => {
        const baseDate = new Date(lessonDate.getTime());

        baseDate.setHours(
            earliestStartTime.getHours(),
            earliestStartTime.getMinutes(),
            0
        );

        setFormData((formData) => ({
            ...formData,
            earliest_potential_start: baseDate
        }));
    }, [setFormData, earliestStartTime, lessonDate]);

    // Setting up an effect to update the form data with the selected lesson date
    useEffect(() => {
        setFormData((formData) => ({
            ...formData,
            date: lessonDate
        }));
    }, [setFormData, lessonDate]);

    // Rendering the component's JSX
    return (
        <>
            <section className="d-flex flex-column align-items-center" style={{paddingTop:"1.7rem"}}>
                <div className="dateTimeSelectionWrapper">
                    <ThemeProvider theme={colortheme}>
                        <LocalizationProvider adapterLocale={svLocale} dateAdapter={AdapterDateFns}>

                            {/* Create the calendar component */}
                            <LessonDateSelect lessonDate={lessonDate} setLessonDate={setLessonDate} />

                            {/* Create the time selection component */}
                            <Typography variant="h5">Tid</Typography>
                            <TimeBetweenSelect
                                earliestStartTime={earliestStartTime}
                                setEarliestStartTime={setEarliestStartTime}
                                latestStartTime={latestStartTime}
                                setLatestStartTime={setLatestStartTime}
                            />
                            {/* Create the lesson length component */}
                            <Typography variant="h5">Längd</Typography>
                            <LessonLengthInput
                                lessonMinutes={lessonMinutes}
                                setLessonMinutes={setLessonMinutes}
                                lessonHours={lessonHours}
                                setLessonHours={setLessonHours}
                            />
                            <Divider />
                            {/* Create the price display */}
                            <TotalSum lessonHours={lessonHours} lessonMinutes={lessonMinutes} />
                        </LocalizationProvider>
                    </ThemeProvider>
                    <div>
                        {/* Create the go to next/continue button */}
                        <Button onClick={goToNext} className="goToNextButton">
                            Gå vidare
                        </Button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ChooseDate
