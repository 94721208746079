import React from 'react';
import { Routes, Route } from "react-router-dom";

import CustomContainer from 'components/CustomContainer'
import Auth from 'components/auth';
import Login from 'components/auth/Login';
import Loading from 'components/Loading';
import Navigation, { studentLinks } from 'components/navigation/student-navigation';
import Home from './home';
import Profile from './Profile';
import AddBooking from './add-booking';
import History from './history'
import moduleName from './app'
import Swipe from 'components/swipe'
import SwipeExp from 'components/swipe/oldCard/exp'
import RateLesson from 'components/RateLesson'
import Maps from 'components/Maps/experiment'
import PaymentMethods from 'components/Payment/PaymentMethods'
import SwishPayment from 'components/Payment/Swish'
import Animation from 'components/Animation/animation'
import TopBar from 'components/navigation/topbar'

import { useFetch } from 'hooks/fetch-hooks'

import wrapperStyles from './wrapper.module.css'

export const TopBarContext = React.createContext();

const App = () => {
  const [doneSwipe, setDonSwipe] = React.useState(0);
  const { data: pendingLessons, isLoading } = useFetch("/api/lesson/student/pending", { authorize: true, dependents: [doneSwipe] })
  const [topContent, setTopContent] = React.useState(null); //TopContent = {childId, childProps}
  const [skipContainer, setSkipContainer] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [totalSteps, setTotalSteps] = React.useState(0);

  if (!isLoading && pendingLessons.length > 0)
    return <Swipe pendingLessons={pendingLessons} quit={() => setDonSwipe(doneSwipe + 1)}></Swipe>
  return (
    <TopBarContext.Provider value={{ topContent, setTopContent, setSkipContainer, step, setStep, totalSteps, setTotalSteps }}>
      <div className={wrapperStyles.wrapper}>
        <TopBar />
        <header className={wrapperStyles.content}>
          <div className={wrapperStyles.scrollparent}>
            <Routes>
              <Route path={studentLinks.HOME + "/*"}
                element={<CustomContainer skipContainer={skipContainer}><Home /></CustomContainer>} />
              <Route path={studentLinks.BOOK + "/*"}
                element={<AddBooking />} />
              <Route path={studentLinks.HISTORY + "/*"}
                element={<CustomContainer><History /></CustomContainer>} />
              <Route path={studentLinks.PROFILE + "/*"}
                element={<CustomContainer><Profile /></CustomContainer>} />

              <Route path="/auth" element={<Auth />} />
              <Route path="/login" element={<Login />} />
              <Route path="/about" element={<Loading />} />

              {/* For Testing */}
              <Route path="/swipe/exp" element={<SwipeExp></SwipeExp>}></Route>
              <Route path="/swipe" element={<Swipe />}></Route>
              <Route path="/rate" element={<RateLesson />}></Route>
              <Route path="/maps" element={<Maps />}></Route>
              <Route path="/methods" element={<PaymentMethods />}></Route>
              <Route path="/animation" element={<Animation />}></Route>
              <Route path="/swish" element={<SwishPayment />}></Route>

              <Route exact path="/*"
                element={<CustomContainer><Home /></CustomContainer>} />
              {/*  */}
            </Routes>
          </div>
        </header>
        <Navigation />
      </div>

      {/* <Footer/> */}
    </TopBarContext.Provider>
  )
}

export default App
