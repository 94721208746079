import React from "react";
import CallMadeIcon from '@mui/icons-material/CallMade';

import styles from './animation.module.css'


const styles1 = {
    transition: 'all 1s ease-in'
};

export function Animation() {
    const [showText, setShowtext] = React.useState(false);
    const [showLogo2, setShowLogo2] = React.useState(false);
    React.useEffect(() => {
        const t = setTimeout(() => {
            setShowtext(true);
        }, 1000)
        return () => clearTimeout(t);
    }, []);
    React.useEffect(() => {
        const t = setTimeout(() => {
            setShowLogo2(true);
        }, 2500);
        return () => clearTimeout(t);
    }, []);
    return (
        <div>
            {
                !showText &&
                <div className={styles.animation}>
                    <header className={`${styles.animation_header}`}>
                        <CallMadeIcon></CallMadeIcon>
                    </header>
                </div>
            }

            {
                showText &&
                <div className={styles.animation2}>
                    <header className={`${styles.animation_header2}`}>
                        <div>
                            Gradelift
                            {
                                showLogo2 &&
                                <div className={`${styles.logo_fade}`}>
                                    <CallMadeIcon></CallMadeIcon>
                                </div>
                            }
                        </div>

                    </header>
                </div>
            }
        </div>
    );
}



export default Animation

/*
useEffect(()=>{
    const timer= setTimeout(() => {
        setItems(swiftArray) */