import { useEffect, useState } from 'react'

export const useLogin = () => {
    const [signed_in, setSigned_in] = useState(null);
    const [role, setRole] = useState(null);
    const [profile, setProfile] = useState(null);
    
    useEffect(() => {
        (async () => {
            const res = await fetch('/api/profile/', { method: 'GET', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } });
            if (res.ok) {
                setSigned_in(true); res.json().then(r => {setRole(r.role); setProfile(r);}).catch(err => alert(err));
            } else {
                setSigned_in(false);
                setRole(null);
            }
        })()
    }, []);
    const refreshData = async () => {
        const res = await fetch('/api/profile/', { method: 'GET', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } });
        if (res.ok) {
            res.json().then(r => {setRole(r.role); setProfile(r);});
        }
    }

    return {
        signed_in,
        setSigned_in,
        role,
        profile,
        setRole,
        setProfile,
        refreshData
    }
}

export const useFetch = (url, {fetchData={ method: "GET" } , authorize=false}) => {
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    if(authorize){
        fetchData['headers'] = { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }
    }
    useEffect(() => {
        (async () => {
            const res = await fetch(url, fetchData)
            res.json().then(r => {setData(r); setIsLoading(false)})
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setData, setIsLoading])
    return { data, isLoading }
}
const fetchHooks = {useLogin, useFetch}
export default fetchHooks