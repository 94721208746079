const months = ['jan', 'feb', 'mar', 'apr', 'maj', 'juni', 'juli', 'aug', 'sep', 'okt', 'nov', 'dec'];
const days = ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'];

const digits = x=> (x<10) ? '0'+x : x.toString()

export const dateToSwe =  d=>{
    /*
        Takes a Date obj and returns presentable swedish date string. Excluding information about time of day.
    */
    return `${days[d.getDay()]} ${digits(d.getDate())} ${months[d.getMonth()]}`
}
export const dateStrToSwe = str =>{
    /**
     *  Takes a Date ISOstring, (2021-02-27T13:20:02.123Z) and returns presentable swedish date string. Excluding information about time of day.
     */
    const d = new Date(str);
    return dateToSwe(d);
}
export const dateStrToShortSwe = dStr=>{
    /**
     *  Takes a Date ISOstring, (2021-02-27T13:20:02.123Z) and returns a short presentable swedish date string.
     *  Only containing information about day and month
     */
    const d = new Date(dStr);
    return `${digits(d.getDate())} ${months[d.getMonth()]}`
}
export const timeToSwe = date =>{
    /**
     * Takes a Date obj and returns a presentable swedish format of time of day
     */
    return `${digits(date.getHours())}:${digits(date.getMinutes())}`
}
export const timeStrToSwe = dateStr=>{
    /**
     * Takes a Date ISOString and returns a presentable swedish format of time of day
     */
    const d = new Date(dateStr)
    return timeToSwe(d)
}
//Arithmetics
const calcTimeDelta = (d1, d2) => {
    return d2 - d1; //milliseconds
}
export const formatTimeDelta = (d1, dstr2) => {
    /**
     * Takes 1 Date ISOString and 1 date obj and formats the difference in time
     * most common d1 = new Date() // current time
     */
    const d2 = new Date(dstr2);
    let msecs = d2 - d1;//milliseconds
    if(msecs < 0)
        return "Datum passerat"
    const s = 1000;
    const m = s*60;
    const h = m*60;
    const d = h*24;
    const y = d*365;
    const prefixes = [
        ["år", "år", y],
        ["dag", "dagar", d],
        ["h", "h", h],
        ["m", "m", m]
    ];
    let res = ""
    let added_count = 0;
    for(let pref of prefixes){
        let [sing, plur, size] = pref;
        let count = Math.floor(msecs/size);
        msecs -= count*size;
        if(count > 1){
            res += count+plur+' ';//should be string
            added_count += 1;
        }
        else if(count === 1){
            res += count+sing+' ';
            added_count += 1;
        }
        if(added_count >= 3)
            break;
    }
    return res;
}
export const d_plus_mins = (d, minutes)=>{
    /**
     * Takes a Date obj and returns a new Date obj minutes later in time
     */
    const d2 = new Date(d);
    d2.setMinutes(d2.getMinutes() + minutes);
    return d2;
}
export const incr_d_mins = (d, mins)=>{
    /**
     * Takes a Date obj and increases it in time with mins, mutates d
     */
    d.setMinutes(d.getMinutes()+mins);
}

export const combine_dates = (date, time) => {
    /*
        Creates a Date obj wth date of first and time of second date objs

        This will give bug for earliest&latest_potential_starttime. If latest is set to after 00:00 i.e next day, compared to earliest
        Maybe fix
    */
    const [str1, str2] = [date.toISOString(), time.toISOString()]
    const parse_str = `${str1.split("T")[0]}T${str2.split("T")[1]}`
    return new Date(parse_str);
}